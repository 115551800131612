import { Box, Dialog, DialogContent, LinearProgress, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'

const Loader = () => {
    const load = useSelector(state => state?.isLoading)
    return (
        <Dialog open={load} maxWidth={'xs'} fullWidth>
            <DialogContent>
                <Box margin={'0 auto'} textAlign={'center'} width={'15rem'} padding={'1rem'}>
                    <Typography variant='body1' style={{ marginBottom: '.5rem' }}>Loading... Please wait</Typography>
                    <LinearProgress variant='indeterminate' />
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default Loader