import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, CardContent, Chip, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Add, Delete, History, Search } from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid'
import { makeStyles } from '@material-ui/styles';
import PageHeader from '../../components/PageHeader';
import { connect, useDispatch } from 'react-redux'
import { axiosInstance, getAllAdmins } from '../../actions/actions';
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.backgroundPrimary}`
      }
    }
  },
  addBtn: {
    marginLeft: 'auto',
    color: '#fff',
    background: theme.backgroundPrimary,
    padding: '0 15px',
    borderRadius: '10px',
    '&:hover': {
      background: theme.backgroundSecondary
    }
  }
}))



const AllAdmins = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [pageSize, setPageSize] = useState(10)
  const { getAllAdmins } = props
  //console.log(props)

  useEffect(() => {
    getAllAdmins()
  }, [getAllAdmins])


  const columns = [
    { field: 'name', headerName: 'FullName', flex: 1, renderCell: (params) => { return (<><Avatar variant='rounded' sizes='large' /> <Typography variant='body2' style={{ marginLeft: '20px' }} >{params.value}</Typography></>) } },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: .5 },
    { field: 'role', headerName: ' Role', flex: .5, renderCell: (params) => { return <Chip size='small' label={params.value} /> } },
    {
      field: 'id', headerName: ' Action', flex: 1, renderCell: (params) => {
        return (
          <>
            <Button size='small' variant='outlined' color='secondary' style={{ marginRight: '.5rem' }} onClick={() => resetAdminPassword(params.value)} disableElevation startIcon={<History fontSize='small' />}>Reset</Button>
            <Button size='small' variant='contained' color='secondary' onClick={() => onAdminDelete(params.value)} disableElevation startIcon={<Delete fontSize='small' />}>Delete</Button>
          </>
        )
      }
    }
  ]

  const onAdminDelete = (id) => {
    swal({
      title: 'Are You Sure',
      text: 'This action will delete all records of this admin',
      icon: 'info',
      buttons: ['Cancel', 'Delete'],
      dangerMode: true
    }).then(async (del) => {
      if (del) {
        dispatch({ type: 'LOAD' })
        try {
          const { data: res } = await axiosInstance.post('/admin/delete', { id })
          if (res?.status === 'success') {
            swal({
              title: 'Success',
              text: 'Admin deleted successfully',
              icon: 'success'
            }).then(() => {
              dispatch({ type: 'EXIT_LOAD' })
              getAllAdmins()
            })
          }
        } catch (error) {
          dispatch({ type: 'EXIT_LOAD' })
          swal({
            title: 'Error',
            text: 'Sorry, could not delete admin. Try again',
            icon: 'error'
          })
        }
      }
    })
  }

  const resetAdminPassword = async (id) => {
    swal({
      title: 'Are You Sure',
      text: 'This action will reset and send a password token to this admin',
      icon: 'info',
      buttons: ['Cancel', 'Proceed'],
      dangerMode: true
    }).then(async (del) => {
      if (del) {
        dispatch({ type: 'LOAD' })
        try {
          const { data: res } = await axiosInstance.post('/admin/reset-password', { id })
          if (res?.status === 'success') {
            swal({
              title: 'Success',
              text: 'Admin password reset successfully',
              icon: 'success'
            }).then(() => {
              dispatch({ type: 'EXIT_LOAD' })
              getAllAdmins()
            })
          }
        } catch (error) {
          dispatch({ type: 'EXIT_LOAD' })
          swal({
            title: 'Error',
            text: 'Sorry, could not reset admin password. Please try again',
            icon: 'error'
          })
        }
      }
    })
  }

  return (
    <div className={classes.root}>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <PageHeader title='All Administrators' link1='Admins' link2='Adminstrators' />
        {props.currentUser.role === 'superadmin' && <Button startIcon={<Add />} size='medium' color='secondary' variant='contained' disableElevation href='/account/users/new'> Add Admin</Button>}
      </div>

      <Card variant='outlined' style={{ borderRadius: '10px', width: '100%' }}>
        <CardContent style={{ padding: '30px' }}>
          <TextField variant='outlined' style={{ marginBottom: '20px' }}
            placeholder='Find admin' fullWidth
            InputProps={{
              startAdornment: <InputAdornment position='start'><Search fontSize='small' /> </InputAdornment>
            }} />
          <DataGrid autoHeight checkboxSelection={false}
            pagination rows={props.admins} rowsPerPageOptions={[5, 10, 20]}
            rowHeight={70} columns={columns}
            pageSize={pageSize} isRowSelectable={false}
            onPageSizeChange={(newSize) => setPageSize(newSize)}
          />

        </CardContent>
      </Card>

    </div>

  )
};

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, { getAllAdmins })(AllAdmins);
